import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In order for an Acronist to leverage a `}<a parentName="p" {...{
        "href": "/Primal%20Spirits",
        "title": "Primal Spirits"
      }}>{`Primal Spirit`}</a>{` in their `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Convictions`}</a>{`, they must complete `}<a parentName="p" {...{
        "href": "/The%20Exchange",
        "title": "The Exchange"
      }}>{`The Exchange`}</a>{`. The Exchange takes many forms, depending on both the particular Spirit involved and the specific cultural practices of the Acronist. In some cultures, such as the in `}<a parentName="p" {...{
        "href": "/Kingdom%20of%20Lashana",
        "title": "Kingdom of Lashana"
      }}>{`Kingdom of Lashana`}</a>{`, their worship of the `}<a parentName="p" {...{
        "href": "/Primal%20Spirit%20of%20Fire",
        "title": "Primal Spirit of Fire"
      }}>{`Primal Fire`}</a>{` gave their most devout worshippers control over the `}<a parentName="p" {...{
        "href": "/Primal%20Aspects",
        "title": "Primal Aspects"
      }}>{`Primal Aspects`}</a>{` of Fire.`}</p>
    <p>{`Typically, exchanges made with any of the Spirits are more simple, either involving a Summoning and capture of part of the Primal Spirit, or consistent worship of a particular Spirit. In rare cases, a Primal Spirit may reach into the physical realm and `}<em parentName="p">{`choose`}</em>{` a Scion, creating a pact with a mortal at birth, as is the case with `}<a parentName="p" {...{
        "href": "/Elemental%20Scion",
        "title": "Elemental Scion"
      }}>{`Elemental Scions`}</a>{` and `}<a parentName="p" {...{
        "href": "/Shifter",
        "title": "Shifter"
      }}>{`Shifters`}</a>{`.`}</p>
    <p>{`The exception, however, is the Primal Soul. The Primal Soul, considered more sentient and `}<em parentName="p">{`aware`}</em>{` than the other Spirits, often requires a `}<strong parentName="p">{`Bargain`}</strong>{` to be struck with the Acronist. Since the `}<a parentName="p" {...{
        "href": "/Primal%20Spirits#corruption-of-the-primal-soul",
        "title": "Primal Spirits"
      }}>{`Corruption of the Primal Soul`}</a>{`, exchanges with the Primal Soul are mostly frowned upon, and in some places, illegal.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      